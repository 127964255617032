<template>
    <div class="home">
      <el-menu :default-active="formInline.status" class="el-menu-demo" mode="horizontal" @select="handleSelect">
        <el-menu-item index="0">全部</el-menu-item>
        <el-menu-item index="1">待处理</el-menu-item>
        <el-menu-item index="2">白名单</el-menu-item>
        <el-menu-item index="3">黑名单</el-menu-item>
      </el-menu>
      <div class="pt20"></div>

        <el-form  :inline="true" :model="formInline" class="demo-form-inline">
            <el-form-item  label="处理时间">
                <el-date-picker
                    v-model="timevalue"
                    type="daterange"
                    align="right"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :picker-options="pickerOptions">
                </el-date-picker>
            </el-form-item>

        <el-form-item style="font-weight: bold;" label="关键字">
            <el-input style="width:300px;" v-model="formInline.query" placeholder="请输入敏感词内容"></el-input>
            <el-button style="margin-left:20px;" type="primary" @click="onSubmit('CHA')">查询</el-button>
        </el-form-item>
      </el-form> 
      
        <div style="text-align: left;margin-left:20px;" @change="onSubmit('CHA')" >
            <el-radio v-model="formInline.sort" label="asc" >升序</el-radio>
            <el-radio v-model="formInline.sort" label="desc" >降序</el-radio>
        <el-button style="margin-left:70%;" type="primary" @click="leadingout()">
          导出
        </el-button>
        </div>
      <div class="pt20"></div>

      <el-table
      v-loading="loading"
        :data="tableData"
        style="width: 100%">

       <el-table-column  width="1" align="center"/>
        <el-table-column label="序号" :index="typeIndex" type="index" show-overflow-tooltip width="50"></el-table-column>
         
        <el-table-column
          prop="name"
          label="敏感词内容">
        </el-table-column>
        <el-table-column
          prop="status"
          label="处理状态">
          <template slot-scope="scope">
        {{scope.row.status==1?'待处理':''}}{{scope.row.status==2?'白名单':''}}
        {{scope.row.status==3?'黑名单':''}}{{scope.row.status==0?'全部':''}}
          </template>
        </el-table-column>
        <el-table-column
          prop="del_at"
          label="处理时间">
        </el-table-column>
        <el-table-column
          prop="operator"
          label="操作人">
        </el-table-column>
        <el-table-column
          label="操作">
          <template slot-scope="scope">
        <el-button v-if="edit == 1" @click="goedit(scope.row)" size="mini" type="info" plain>审核</el-button>
        <el-button v-if="detail == 1" @click="goedit2(scope.row)" size="mini" type="info" plain>查看</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pt20"></div>
        
    <el-pagination
      @current-change="handleCurrentChange"
      current-page.sync="current_page"
      :page-size="per_page"
      layout="total,prev, pager, next, jumper"
      :total="total">
    </el-pagination>
      
    </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import config from '../../lib/config'
import formatDate from '../../lib/dateFormat'
import globla from '../../globla.js'

export default {
  name: 'Home',
  components: {
    
  },
  data(){
    return {
      loading:true,
      edit:location.edit,
      detail:location.detail,
      total:0,
      per_page: 0,
      current_page:1,
      tableData: [],
      formInline: {
         sdate:'',
         edate:'',
         query:'',
         page:1,
         sort:'desc',
         status:'0',//状态 0 全部 1待处理 2白名单 3黑名单
      },
      timevalue:'',
      timevalue2:'',
      // 选中数组
      ghs: [],
      report:[],//反馈问题列表
     //选中的记录数量
     initdata:{
         sdate:'',
         edate:'',
         query:'',
         page:1,
         sort:'desc',
         status:'0',//状态 0 全部 1待处理 2白名单 3黑名单
     },
     selectedNum:0,
     pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {  
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
    }
  },
  methods: {
      leadingout(){
           
  let token = localStorage.getItem('Authorization');
    
window.open(globla.SERVE_URL+'/'+config.mgExport+'?sdate='+this.formInline.sdate+'&edate='+this.formInline.edate+
'&query='+this.formInline.query+'&page='+this.formInline.page+'&sort='+this.formInline.sort+'&status='+this.formInline.status
+'&api_token='+token, "_blank");
      },
      handleSelect(key) {
        this.formInline.status = key;
        this.onSubmit('CHA');
      },
      onSubmit(CHA){
        let _this = this;
         _this.loading = true;
        if(CHA == 'CHA'){
          this.formInline.page = 1;
        }
          _this.formInline.sdate = '';
          _this.formInline.edate = '';
          if(_this.timevalue){
            _this.formInline.sdate = formatDate(_this.timevalue[0]);
            _this.formInline.edate = formatDate(_this.timevalue[1]);
          }
        axios.get(config.mgList,{params:this.formInline})
        .then(function (response) {
            if(response.data.code == 200){
              _this.tableData = response.data.data.data
              _this.setpage(response.data.data)
            }else{
              _this.$message.error(response.data.message);
            }
              _this.loading = false
          }) 
        .catch(function (error) {
          _this.$message.error(error);
        });
      },
    setpage(page){
      this.total = page.total;
      this.per_page = page.per_page;
      this.current_page = page.current_page;
    },typeIndex(index) {
      let _this = this; //处理分页数据的 index
      return (_this.formInline.page - 1) * 20 + index + 1;
    },
      handleCurrentChange(val) {
        this.formInline.page = val;
        this.onSubmit();
        document.getElementsByClassName('el-main')[0].scrollTop = 0;
      },
      goedit(obj){
        sessionStorage.setItem("edit", JSON.stringify(obj));
       // this.$router.push('/sensitive/sensitivedetail');
        sessionStorage.setItem("pathname", location.pathname);
        window.open('/sensitive/sensitivedetail');
      },
      goedit2(obj){
        obj.look = true;
        sessionStorage.setItem("edit", JSON.stringify(obj));
       // this.$router.push('/sensitive/sensitivedetail');
        sessionStorage.setItem("pathname", location.pathname);
        window.open('/sensitive/sensitivedetail');
      },
    init(){
        let _this = this;
        let a = sessionStorage.getItem('formInline');
        this.formInline = a?JSON.parse(a):this.initdata;
        axios.get(config.mgList,{params:this.formInline})
        .then(function (response) {
            if(response.data.code == 200){
              _this.tableData = response.data.data.data
              _this.setpage(response.data.data)
            }else{
              _this.$message.error(response.data.message);
            }
              _this.loading = false
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
    }
  },
  created(){
      this.init();
  }
}
</script>
<style scoped>
.left{
    width: 335px;
}
</style>